import { getWindow } from '@exp/exp-utils/helper/browser';

const cmdLogPageRequest = (pathname) => {
  getWindow()._expDataLayer.push({
    schema: 'add_page_request',
    version: 'v1',
    data: {
      virtual_path: pathname }});
};

export default cmdLogPageRequest;
