import { PropertyDefinitionBlock, ObjectDefinitionBlock, CollectionDefinitionBlock, CalculatedDefinitionBlock } from '@exp/exp-utils/schema/schemaDefinitionBlocks';

import * as schemaTransforms from '@tcc/shared/src/experiments/experimentSchemaTransforms';

// Handlers
import AddPagePerfHandler from './handlers/addPagePerf';
import AddPerfHandler from './handlers/addPerf';
import AddEventHandler from './handlers/addEvent';
import AddExperimentAssignment from './handlers/addExperimentAssignment';
import AddPromotion from './handlers/addPromotion';
import AddEcommEvent from './handlers/addEcommEvent';
import AddPageRequest from './handlers/addPageRequest';
import AddImpression from './handlers/addImpression';
import AddVirtualPagePerf from './handlers/addVirtualPagePerf';
import GetTrackingValues from './handlers/getTrackingValues';
import AddGenericConversion from './handlers/addGenericConversion';
import GetVariantForExperiment from './handlers/getVariantForExperiment';

/*
For documentation on how to use the schema framework, refer to
https://github.secureserver.net/Experimentation/exp-utils/blob/master/test/schema/example/exampleSchema.js
*/

/*      Shared Transform Key Maps  */

const _valueTransformMap = {
  ALL: 'value',
  EVENT_SVC: 'ecomm_value',
  TEALIUM: 'order_total_usd' };

const _productPriceTransformMap = {
  ALL: 'price',
  TEALIUM: 'product_price_usd' };

const _currencyTransformMap = {
  ALL: 'currency',
  TEALIUM: 'order_currency' };

const _itcTransformMap = {
  ALL: 'item_tracking_code',
  GA: 'item_tracking_code_product',
  EVENT_SVC: 'itemTrackingCode' };

/*      Shared Object Schemas      */

const _allSchemaBlocks = () => {
  return [
    new PropertyDefinitionBlock('custom_properties')
      .optional()
      .sinks(['EVENT_SVC'])
  ];
};

const _experimentAssignmentBlocks = () => {
  return [
    new PropertyDefinitionBlock('experiment_id')
      .required(),
    new PropertyDefinitionBlock('variant_id')
      .required(),
    new PropertyDefinitionBlock('content_id')
      .optional(),
    new PropertyDefinitionBlock('experiment_source')
      .optional(),
    new ObjectDefinitionBlock()
      .substitute(_allSchemaBlocks())
  ];
};

const _eventObjV2 = () => {
  return [
    new PropertyDefinitionBlock('eid')
      .required(),
    new PropertyDefinitionBlock('href')
      .optional(),
    new PropertyDefinitionBlock('tcode')
      .optional(),
    new PropertyDefinitionBlock('tms')
      .optional(),
    new PropertyDefinitionBlock('ci')
      .optional(),
    new PropertyDefinitionBlock('properties')
      .optional()
  ];
};

const _eventObjV1 = () => {
  return [
    new PropertyDefinitionBlock('eid')
      .optional(),
    new PropertyDefinitionBlock('properties')
      .optional(),
    new PropertyDefinitionBlock('dom_element')
      .optional(),
    new PropertyDefinitionBlock('dom_event')
      .optional()
  ];
};

const _promotionBlocks = (eventObj) => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .sinks(['GA']),
    new PropertyDefinitionBlock('name')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('creative_name')
      .optional()
      .sinks(['GA']),
    new PropertyDefinitionBlock('creative_slot')
      .optional()
      .sinks(['GA']),
    new ObjectDefinitionBlock()
      .substitute(eventObj)
      .sinks(['EVENT_SVC'])
  ];
};

const _cartTypeProperty = () => {
  return new PropertyDefinitionBlock('cart_type')
    .optional()
    .sinks(['GA', 'EVENT_SVC']);
};

// prdct supports v1 purchase
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const _productBlocks = () => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .transformKeys({ TEALIUM: 'product_id' }),
    new PropertyDefinitionBlock('quantity')
      .required()
      .transformKeys({ TEALIUM: 'product_quantity' }),
    new PropertyDefinitionBlock('name')
      .optional()
      .sinks(['GA', 'EVENT_SVC']),
    new PropertyDefinitionBlock('brand')
      .optional()
      .sinks(['GA', 'EVENT_SVC']),
    new PropertyDefinitionBlock('variant')
      .optional()
      .sinks(['GA', 'EVENT_SVC']),
    new PropertyDefinitionBlock('coupon')
      .optional()
      .sinks(['GA', 'EVENT_SVC'])
  ];
};

// Support Purchase V1
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const _cartProductBlocks = (itemExtensions) => {
  return [
    _cartTypeProperty(),
    new PropertyDefinitionBlock('currency')
      .optional()
      .transformKeys(_currencyTransformMap),
    new PropertyDefinitionBlock('coupon')
      .optional()
      .transformKeys({ TEALIUM: 'source_code' }),

    // In addition to the fields defined by the 'product' map, the
    // objects in this collection will also require the price field.
    new CollectionDefinitionBlock().map('items', _productBlocks())
      .extend([
        // This is an example of the 'product' object being extended
        // to require additional properties
        new PropertyDefinitionBlock('full_product_name')
          .optional()
          .sinks(['TEALIUM'])
          .transformKeys({ TEALIUM: 'product_name' }),
        new PropertyDefinitionBlock('cj_product_id')
          .optional()
          .sinks(['TEALIUM']),
        new PropertyDefinitionBlock('cj_product_price_usd')
          .optional()
          .sinks(['TEALIUM']),
        new PropertyDefinitionBlock('cj_product_quantity')
          .optional()
          .sinks(['TEALIUM']),
        new PropertyDefinitionBlock('item_tracking_code')
          .optional()
          .transformKeys(_itcTransformMap),
        new PropertyDefinitionBlock('product_category_id')
          .optional()
          .sinks(['TEALIUM'])
          .transformKeys({ TEALIUM: 'product_category' }),
        new PropertyDefinitionBlock('category')
          .optional()
          .sinks(['GA', 'EVENT_SVC'])
      ].concat(itemExtensions))
      .transform({ EVENT_SVC: [JSON.stringify] })
      .withMinElements(1)
  ];
};

// Supports Purchase V1
// TODO Brandon 11/16/2018 Remove after Cart moves to V2 Purchase
// https://jira.godaddy.com/browse/EXP-1107
const _gaPurchaseBlocks = () => {
  return [
    new PropertyDefinitionBlock('transaction_id')
      .required()
      .transformKeys({ TEALIUM: 'order_id' }),
    new PropertyDefinitionBlock('activation_redirect')
      .optional(),
    new PropertyDefinitionBlock('first_order')
      .optional(),
    new PropertyDefinitionBlock('new_customer')
      .optional(),
    new PropertyDefinitionBlock('order_discount_usd')
      .optional()
      .transformKeys({ TEALIUM: 'order_discount' }),
    new PropertyDefinitionBlock('order_total_new_usd')
      .optional()
      .doNotOutput('GA'),
    new PropertyDefinitionBlock('order_total_renewal_usd')
      .optional()
      .doNotOutput('GA'),
    new PropertyDefinitionBlock('order_from_website')
      .optional()
      .sinks(['TEALIUM']),
    new PropertyDefinitionBlock('page_type')
      .optional()
      .sinks(['TEALIUM']),
    new PropertyDefinitionBlock('order_region')
      .optional()
      .sinks(['TEALIUM']),
    new PropertyDefinitionBlock('payment_pending')
      .optional(),
    new PropertyDefinitionBlock('payment_processor')
      .optional(),
    new ObjectDefinitionBlock()
      .substitute(_cartProductBlocks([
        // _cartProductObj accepts an array of properties to extend the items object map
        new PropertyDefinitionBlock('price')
          .required()
          .transformKeys(_productPriceTransformMap)
      ]))
      .extend([
        new PropertyDefinitionBlock('currency')
          .required()
          .transformKeys(_currencyTransformMap),
        new PropertyDefinitionBlock('value')
          .required()
          .transformKeys(_valueTransformMap)
      ])
      .transform({
        TEALIUM: [schemaTransforms.transformForTealium]
      })
  ];
};

const _purchaseCommon = () => {
  return [
    new CalculatedDefinitionBlock('new_vs_renewal')
      .sinks(['GA', 'EVENT_SVC'])
      .transform(schemaTransforms.calcNewVsRenewal),
    new CalculatedDefinitionBlock('eid')
      .sinks(['GA', 'EVENT_SVC'])
      .transform(schemaTransforms.calcPurchaseEid)
      .transformKeys({ EVENT_SVC: 'e_id' })
  ];
};

const _checkoutProductBlocks = (staticEid) => {
  return [
    // Extend the cart_prdct object with extra properties
    new ObjectDefinitionBlock().substitute(_cartProductBlocks([
      // _cartProductObj accepts an array of properties to extend the items object map
      new PropertyDefinitionBlock('price')
        .optional()
        .transformKeys(_productPriceTransformMap)
    ])).extend([
      // This is extending the root object, not any of the object maps (such as items or packages)
      new PropertyDefinitionBlock('checkout_step')
        .required(),
      new PropertyDefinitionBlock('checkout_option')
        .optional(),
      new CalculatedDefinitionBlock('eid')
        .transform(staticEid)
        .transformKeys({ EVENT_SVC: 'e_id' })
        .sinks(['GA', 'EVENT_SVC']),
      new PropertyDefinitionBlock('eid_label')
        .optional()
        .transformKeys({ EVENT_SVC: 'event_label' })
        .sinks(['GA', 'EVENT_SVC'])
    ])
  ];
};

// supports v1 add_to_cart, v1 product_impression and v2 purchase
const _baseProductBlocks = () => {
  return [
    new PropertyDefinitionBlock('id')
      .required()
      .transformKeys({ TEALIUM: 'product_id' }),
    new PropertyDefinitionBlock('qt')
      .required()
      .transformKeys({ ALL: 'quantity', TEALIUM: 'product_quantity' }),
    new PropertyDefinitionBlock('ca')
      .optional()
      .transformKeys({ ALL: 'category', TEALIUM: 'product_category_name' }),
    new PropertyDefinitionBlock('br')
      .optional()
      .sinks(['GA', 'EVENT_SVC'])
      .transformKeys({ ALL: 'brand' }),
    new PropertyDefinitionBlock('va')
      .optional()
      .sinks(['GA', 'EVENT_SVC'])
      .transformKeys({ ALL: 'variant' }),
    new PropertyDefinitionBlock('cc')
      .optional()
      .sinks(['GA', 'EVENT_SVC'])
      .transformKeys({ ALL: 'coupon' }),
    new PropertyDefinitionBlock('pr')
      .optional()
      .transformKeys(_productPriceTransformMap)
  ];
};

// V1 Product Impression and Add To Cart Product Map
const _ecommProductBlocks = () => {
  return [
    new CollectionDefinitionBlock().map('items', _baseProductBlocks())
      .extend([
        new PropertyDefinitionBlock('name')
          .optional()
          .sinks(['GA', 'EVENT_SVC']),
        new PropertyDefinitionBlock('itc')
          .optional()
          .transformKeys(_itcTransformMap)
      ])
      .transform({ EVENT_SVC: [JSON.stringify] })
      .withMinElements(1)
  ];
};

// V2 Purchase Product Map
const _purchaseProductBlocks = () => {
  return [
    new CollectionDefinitionBlock().map('items', _baseProductBlocks()).extend([
      new PropertyDefinitionBlock('pr')
        .required()
        .transformKeys(_productPriceTransformMap),
      new PropertyDefinitionBlock('prcaid')
        .optional()
        .sinks(['TEALIUM'])
        .transformKeys({ TEALIUM: 'product_category' }),
      new PropertyDefinitionBlock('cj')
        .optional()
        .sinks(['TEALIUM']),
      new PropertyDefinitionBlock('pritc')
        .optional()
        .transformKeys(_itcTransformMap)
    ]).transform({ EVENT_SVC: [JSON.stringify] })
      .withMinElements(1)
  ];
};

// Supports v1 add_to_cart, v1 product_impression and v2 purchase
const _packageBlocks = () => {
  return [
    new PropertyDefinitionBlock('pkgid')
      .optional()
      .transformKeys({ ALL: 'package_id', EVENT_SVC: 'id' }),
    new PropertyDefinitionBlock('pkgpr')
      .optional()
      .transformKeys({ EVENT_SVC: 'price', TEALIUM: 'package_price_usd' }),
    new PropertyDefinitionBlock('pkgca')
      .optional()
      .transformKeys({ EVENT_SVC: 'category', TEALIUM: 'package_category' }),
    new PropertyDefinitionBlock('pkgqt')
      .optional()
      .transformKeys({ EVENT_SVC: 'quantity', TEALIUM: 'package_quantity' })
  ];
};

// V1 Product Impression, Add To Cart, and Remove From Cart Product map
const _ecommPackageBlocks = () => {
  return [
    new CollectionDefinitionBlock().map('pkgs', _packageBlocks())
      .transform({ EVENT_SVC: [JSON.stringify] })
      .transformKeys({ EVENT_SVC: 'packages' })
  ];
};

// V1 Common Blocks Between Add to Cart and Remove From Cart Schemas
const _cartChangeBlocks = (staticEid) => {
  return [
    new PropertyDefinitionBlock('el')
      .optional()
      .sinks(['GA', 'EVENT_SVC'])
      .transformKeys({ GA: 'eid_label', EVENT_SVC: 'event_label' }),
    new CalculatedDefinitionBlock('eid')
      .sinks(['GA', 'EVENT_SVC'])
      .transform(staticEid)
      .transformKeys({ EVENT_SVC: 'e_id' }),
    new PropertyDefinitionBlock('currency')
      .optional()
      .transformKeys(_currencyTransformMap),
    new PropertyDefinitionBlock('value')
      .optional()
      .transformKeys(_valueTransformMap),
    new ObjectDefinitionBlock()
      .substitute(_ecommProductBlocks())
      .transform({
        TEALIUM: [schemaTransforms.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_ecommPackageBlocks())
      .transform({
        GA: [schemaTransforms.getPackagesForGA],
        TEALIUM: [schemaTransforms.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_allSchemaBlocks()),
    _cartTypeProperty()
  ];
};

// V2 Purchase data map
const _purchaseBlocks = () => {
  return [
    new PropertyDefinitionBlock('cu')
      .required()
      .transformKeys(_currencyTransformMap),
    new PropertyDefinitionBlock('ti')
      .required()
      .transformKeys({ ALL: 'transaction_id', TEALIUM: 'order_id' }),
    new PropertyDefinitionBlock('tr')
      .required()
      .transformKeys(_valueTransformMap),
    new PropertyDefinitionBlock('tcc')
      .optional()
      .transformKeys({ ALL: 'coupon', TEALIUM: 'source_code' }),
    new PropertyDefinitionBlock('fo')
      .optional()
      .transformKeys({ ALL: 'first_order' }),
    new PropertyDefinitionBlock('nc')
      .optional()
      .transformKeys({ ALL: 'new_customer' }),
    new PropertyDefinitionBlock('tdr')
      .optional()
      .transformKeys({ ALL: 'order_discount_usd' }),
    new PropertyDefinitionBlock('tr_new')
      .optional()
      .doNotOutput('GA')
      .transformKeys({ ALL: 'order_total_new_usd' }),
    new PropertyDefinitionBlock('tr_renew')
      .optional()
      .doNotOutput('GA')
      .transformKeys({ ALL: 'order_total_renewal_usd' }),
    new PropertyDefinitionBlock('pp')
      .optional()
      .transformKeys({ ALL: 'payment_pending' }),
    new PropertyDefinitionBlock('psrc')
      .optional()
      .sinks(['GA', 'EVENT_SVC'])
      .transformKeys({ ALL: 'payment_processor' }),
    new ObjectDefinitionBlock()
      .substitute(_ecommPackageBlocks())
      .transform({
        TEALIUM: [schemaTransforms.transformForTealiumV2]
      }),
    new ObjectDefinitionBlock()
      .substitute(_purchaseProductBlocks())
      .transform({
        TEALIUM: [schemaTransforms.transformForTealiumV2]
      })
  ];
};

const navTimingBlocks = () => {
  return [
    new PropertyDefinitionBlock('navigationStart').required(),
    new PropertyDefinitionBlock('fetchStart').optional(),
    new PropertyDefinitionBlock('domainLookupStart').optional(),
    new PropertyDefinitionBlock('domainLookupEnd').optional(),
    new PropertyDefinitionBlock('connectStart').optional(),
    new PropertyDefinitionBlock('connectEnd').optional(),
    new PropertyDefinitionBlock('requestStart').optional(),
    new PropertyDefinitionBlock('responseStart').optional(),
    new PropertyDefinitionBlock('responseEnd').optional(),
    new PropertyDefinitionBlock('domLoading').optional(),
    new PropertyDefinitionBlock('domInteractive').optional(),
    new PropertyDefinitionBlock('domContentLoaded').optional(),
    new PropertyDefinitionBlock('domComplete').optional(),
    new PropertyDefinitionBlock('loadEventStart').required(),
    new PropertyDefinitionBlock('loadEventEnd').optional(),
    new PropertyDefinitionBlock('transferSize').optional(),
    new PropertyDefinitionBlock('encodedBodySize').optional(),
    new PropertyDefinitionBlock('decodedBodySize').optional()
  ];
};

const genericConversionBlocks = () => {
  return [
    new ObjectDefinitionBlock().map('properties', [
      new PropertyDefinitionBlock('virtual_order_id')
        .optional().
        transformKeys({ TEALIUM: 'order_id' }),
      new PropertyDefinitionBlock('app_name')
        .optional(),
      new PropertyDefinitionBlock('package_id')
        .optional(),
      new PropertyDefinitionBlock('package_category')
        .optional()
    ]),
    new PropertyDefinitionBlock('area')
      .doNotOutput()
      .required(),
    new PropertyDefinitionBlock('product')
      .doNotOutput()
      .required(),
    new PropertyDefinitionBlock('revenue')
      .doNotOutput()
      .required(),
    new PropertyDefinitionBlock('action')
      .doNotOutput()
      .required(),
    new CalculatedDefinitionBlock('eid')
      .transform((_, siblings) => {
        return `tcc.conversion.${siblings.area}.${siblings.product}.${siblings.revenue}.${siblings.action}`;
      })
  ];
};

const _getVariantForExpBlocks = () => {
  return [
    new PropertyDefinitionBlock('experiment_id')
      .required(),
    new PropertyDefinitionBlock('callback')
      .required(),
    new PropertyDefinitionBlock('attributes')
      .optional(),
    new ObjectDefinitionBlock()
      .substitute(_allSchemaBlocks())
  ];
};

const _experimentAssignmentSchema = () => {
  return {
    // Becauses this schema handler calls the add_event handler,
    // the sink defined here will override the add_event schema sink
    sinks: ['EVENT_SVC'],
    handler: AddExperimentAssignment,
    data: [
      new ObjectDefinitionBlock()
        .substitute(_experimentAssignmentBlocks())
    ]
  };
};

const _promoClickSchema = (eventObj) => {
  return {
    handler: AddPromotion,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new ObjectDefinitionBlock()
        .substitute(_promotionBlocks(eventObj))
        .transform({ GA: [(input) => {
          return { promotions: [input] };
        }] }),
      new ObjectDefinitionBlock()
        .substitute(_allSchemaBlocks())
    ]
  };
};

const _promoImpressionSchema = (eventObj) => {
  return {
    handler: AddPromotion,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new CollectionDefinitionBlock()
        .map('impressions', _promotionBlocks(eventObj))
        .transformKeys({ ALL: 'promotions' })
        .withMinElements(1),
      new ObjectDefinitionBlock()
        .substitute(_allSchemaBlocks())
    ]
  };
};

const _addEventSchema = (eventObj) => {
  return {
    handler: AddEventHandler,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new PropertyDefinitionBlock('type')
        .required()
        .transform({ ALL: [schemaTransforms.filterLogType] }),
      new ObjectDefinitionBlock()
        .substitute(eventObj)
        .transform({ ALL: [schemaTransforms.validateEid] }),
      new PropertyDefinitionBlock('event_label').optional(),
      new ObjectDefinitionBlock()
        .substitute(_allSchemaBlocks())
    ]
  };
};

const _addImpressionSchema = (eventObj) => {
  return {
    handler: AddImpression,
    sinks: ['GA', 'EVENT_SVC'],
    data: [
      new ObjectDefinitionBlock()
        .substitute(eventObj)
        .transform({ ALL: [schemaTransforms.validateEid] }),
      new ObjectDefinitionBlock()
        .substitute(_allSchemaBlocks())
    ]
  };
};

/*      Command Schemas     */

const commandSchemaDefinitions = {
  add_event: {
    v1: _addEventSchema(_eventObjV1()),
    v2: _addEventSchema(_eventObjV2())
  },
  add_generic_conversion: {
    v1: {
      handler: AddGenericConversion,
      sinks: ['GA', 'EVENT_SVC', 'TEALIUM'],
      data: [
        new ObjectDefinitionBlock()
          .substitute(genericConversionBlocks())
          .transform({
            TEALIUM: [schemaTransforms.unnestProperties]
          }),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  },
  add_impression: {
    v1: _addImpressionSchema(_eventObjV1()),
    v2: _addImpressionSchema(_eventObjV2())
  },
  add_page_request: {
    v1: {
      handler: AddPageRequest,
      sinks: ['GA', 'EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtual_path')
          .optional(),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  },
  add_virtual_page_perf: {
    v1: {
      handler: AddVirtualPagePerf,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('virtual_path')
          .required(),
        new ObjectDefinitionBlock()
          .map('timing_metrics', navTimingBlocks()),
        new PropertyDefinitionBlock('perf_mark_name')
          .optional(),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  },
  add_page_perf: {
    v1: {
      handler: AddPagePerfHandler,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ],
      // add_page_perf still depends on add_perf in a legacy way.
      // If you need to control where the output for this schema will go,
      // adjust the 'add_perf' output group
      sinks: ['EVENT_SVC']
    }
  },
  // Ad-hoc perf interface.
  // add_page_perf reuses this schema to log perf data
  // Helper libraries / consumers use this to log virtual page requests
  add_perf: {
    v1: {
      handler: AddPerfHandler,
      sinks: ['EVENT_SVC'],
      data: [
        new PropertyDefinitionBlock('type')
          .required()
          .transform({ ALL: [schemaTransforms.filterLogType] }),
        new PropertyDefinitionBlock('properties')
          .optional(),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  },
  get_tracking_values: {
    v1: {
      handler: GetTrackingValues,
      data: [
        new PropertyDefinitionBlock('callback').required()
      ]
    }
  },
  get_variant_for_experiment: {
    v1: {
      handler: GetVariantForExperiment,
      data: _getVariantForExpBlocks()
    },
    v2: {
      handler: GetVariantForExperiment,
      data: [
        new ObjectDefinitionBlock()
          .substitute(_getVariantForExpBlocks()).extend([
            new PropertyDefinitionBlock('traffic_type')
              .required(),
            new PropertyDefinitionBlock('configuration')
              .optional()
          ])
      ]
    }
  },
  // DEPRECATED. Use add_experiment_assignment
  add_experiment: {
    v1: {
      handler: AddExperimentAssignment,
      sinks: ['EVENT_SVC'],
      data: [
        new ObjectDefinitionBlock()
          .substitute(_experimentAssignmentBlocks()).extend([
            new PropertyDefinitionBlock('experiment_type').optional()
          ])
      ]
    }
  },
  add_experiment_assignment: [
    {
      type: 'abn',
      v1: _experimentAssignmentSchema()
    },
    {
      type: 'mvt',
      v1: _experimentAssignmentSchema()
    }
  ],
  add_promotion: [
    {
      type: 'click',
      v1: _promoClickSchema(_eventObjV1()),
      v2: _promoClickSchema(_eventObjV2())
    },
    {
      type: 'impression',
      v1: _promoImpressionSchema(_eventObjV1()),
      v2: _promoImpressionSchema(_eventObjV2())
    }
  ],
  add_ecomm_event: [
    {
      type: 'product_impression',
      v1: {
        handler: AddEcommEvent,
        sinks: ['TEALIUM', 'EVENT_SVC'],
        data: [
          new PropertyDefinitionBlock('el')
            .optional()
            .sinks(['GA', 'EVENT_SVC'])
            .transformKeys({ GA: 'eid_label', EVENT_SVC: 'event_label' }),
          new CalculatedDefinitionBlock('eid')
            .sinks(['GA', 'EVENT_SVC'])
            .transform('gpd.exp.tcc.product-impression.success')
            .transformKeys({ EVENT_SVC: 'e_id' }),
          new ObjectDefinitionBlock().substitute(_ecommProductBlocks())
            .transform({
              TEALIUM: [schemaTransforms.transformForTealiumV2]
            }),
          new ObjectDefinitionBlock().substitute(_ecommPackageBlocks())
            .transform({
              GA: [schemaTransforms.getPackagesForGA],
              TEALIUM: [schemaTransforms.transformForTealiumV2]
            }),
          new ObjectDefinitionBlock()
            .substitute(_allSchemaBlocks()),
          _cartTypeProperty()
        ]
      }
    },
    {
      type: 'add_to_cart',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(
            _cartChangeBlocks('gpd.exp.tcc.add-to-cart.success'))
        ]
      }
    },
    {
      type: 'remove_from_cart',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(
            _cartChangeBlocks('gpd.exp.tcc.remove-from-cart.success'))
        ]
      }
    },
    {
      type: 'begin_checkout',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(_checkoutProductBlocks(
            'gpd.exp.tcc.begin-checkout.success')),
          new ObjectDefinitionBlock()
            .substitute(_allSchemaBlocks())
        ]
      }
    },
    {
      type: 'checkout_progress',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock().substitute(_checkoutProductBlocks(
            'gpd.exp.tcc.checkout-progress.success')).extend([
            new PropertyDefinitionBlock('payment_processor')
              .optional(),
            new ObjectDefinitionBlock()
              .substitute(_allSchemaBlocks())
          ])
        ]
      }
    },
    {
      type: 'purchase',
      v1: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock()
            .substitute(_gaPurchaseBlocks())
            .extend(_purchaseCommon())
            .transform({ TEALIUM: [schemaTransforms.emptyStringForUndefined] }),
          new ObjectDefinitionBlock()
            .substitute(_allSchemaBlocks())
        ]
      },
      v2: {
        handler: AddEcommEvent,
        sinks: ['GA', 'TEALIUM', 'EVENT_SVC'],
        data: [
          new ObjectDefinitionBlock()
            .substitute(_purchaseBlocks())
            .extend(_purchaseCommon())
            .transform({
              GA: [schemaTransforms.getPackagesForGA],
              TEALIUM: [schemaTransforms.emptyStringForUndefined]
            }),
          new ObjectDefinitionBlock()
            .substitute(_allSchemaBlocks())
        ]
      }
    }]
};

export default commandSchemaDefinitions;
