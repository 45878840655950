import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

class AddImpressionHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: () => { this._handle(); }
    });
  }

  _handle() {
    // call the 'add_event' schema

    const addEventVersion = this.schemaVersion === 'v1' ? 'v1' : 'v2';
    this.schemaHelper.handleSchema('add_event', undefined, addEventVersion, {
      type: 'impression',
      eid: this.data.ALL.eid,
      properties: this.data.ALL.properties,
      custom_properties: this.data.EVENT_SVC.custom_properties,
      dom_element: this.data.ALL.dom_element
    },
    this.extras,
    // this schema's sinks will override add_event's sinks
    this.sinks);
  }
}

export default AddImpressionHandler;
