import { error } from '@exp/exp-utils/helper/logger';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { getVariantId } from '../../splitio/splitio';

class GetVariantForExperimentHandler extends SchemaHandler { // eslint-disable-line id-length

  process() {
    super.process({
      ALL: (input) => { this._handleSplitio(input); }
    });
  }

  _handleSplitio(input) {
    error('The `get_variant_for_experiment` Traffic interface is being deprecated. Please migrate to the Hivemind SDK.', 'https://github.secureserver.net/hivemind/hivemind-onboarding#hivemind-sdks');
    getVariantId(input.experiment_id, input.attributes, input.traffic_type, input.configuration, ((variantId) => {
      input.callback(variantId);
    }).bind(this));
  }
}

export default GetVariantForExperimentHandler;
