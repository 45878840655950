import { merge } from '@exp/exp-utils/helper/object';

import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';
import { sendEvent } from '@tcc/shared/src/traffic/eventSend';

import { sendEcommerceEvent as sendGaEcommerceEvent } from '../../ga/ga';
import { sendEcommerceEvent as sendTealiumEcommerceEvent } from '../../tealium/tealium';
import { createEventProperties, addEventProperties, createTData, getEventSvcProperties } from '../../traffic/eventProperties';

let _isDuplicatePurchase = false;

class AddEcommEventHandler extends SchemaHandler {
  preProcess() {
    if (this.schemaType === 'purchase') {
      if (_isDuplicatePurchase) {
        throw 'cmd: LogEcommEvent duplicate purchase event detected, ignoring duplicate';
      }
      _isDuplicatePurchase = true;
    }

    this._buildEvent();
  }

  process() {
    super.process({
      TEALIUM: (input) => { this._handleTealium(input); },
      GA: (input) => { this._handleGA(input); },
      EVENT_SVC: (input) => { this._handleEventSvc(input); }
    });
  }

  _handleTealium(input) {
    sendTealiumEcommerceEvent(this.schemaType, input);
  }

  _handleGA(input) {
    sendGaEcommerceEvent(this.schemaType, input, merge(this.tData.getProperties(), this.extras));
  }

  _handleEventSvc(input) {
    sendEvent(
      merge(this.pageEvent.getProperties(), getEventSvcProperties(), input),
      '/pageEvents.aspx',
      'GET');
  }

  _buildEvent() {
    this.tData = createTData(this.data);

    // In order to maintain purchase events going to legacy, we need to use 'page.event' when sending purchase events
    // However, lets use the current schema type when this is not an event which needs to go to legacy.
    this.pageEvent = createEventProperties(this.schemaType === 'purchase' ?
      'page.event' : this.schemaType, this.tData);

    addEventProperties(this.pageEvent, 'ecommerce');

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);
  }
}

const _setDuplicatePurchaseFlag = (value) => {
  _isDuplicatePurchase = value;
};

export default AddEcommEventHandler;

// Private exports for testing
export { _setDuplicatePurchaseFlag };
