import { getWindow } from '@exp/exp-utils/helper/browser';

const cmdLogPagePerf = () => {
  getWindow()._expDataLayer.push({
    schema: 'add_page_perf',
    version: 'v1'
  });
};

export default cmdLogPagePerf;
