import { getWindow } from '@exp/exp-utils/helper/browser';
import { getObjFromTDataList } from '@tcc/shared/src/helpers/tdata';

const cmdLogImpression = (eid, usrin, domElement) => {
  getWindow()._expDataLayer.push({
    schema: 'add_impression',
    version: 'v1',
    data: {
      eid: eid,
      properties: getObjFromTDataList(usrin),
      dom_element: domElement }});
};

export default cmdLogImpression;
