import { debug } from '@exp/exp-utils/helper/logger';

import { getWindow, getDocument, formatPageName } from '@exp/exp-utils/helper/browser';
import { getCookieValues, setCookie } from '@exp/exp-utils/helper/cookie';
import { getCleanQueryString } from '@exp/exp-utils/helper/url';
import { merge } from '@exp/exp-utils/helper/object';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import page from '@tcc/shared/src/traffic/eventPageProperties';
import { sendEvent } from '@tcc/shared/src/traffic/eventSend';
import { createEventProperties, getEventSvcProperties, createTData } from '../../traffic/eventProperties';
import { sendPageView as sendGaPageView } from '../../ga/ga';

let lastPathName;

class AddPageRequestHandler extends SchemaHandler {
  process() {
    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); },
      GA: (input) => { this._handleGA(input); }
    });
  }

  _handleEventSvc() {
    sendEvent(
      merge(page.getProperties(), getEventSvcProperties()),
      '/image.aspx',
      'GET');
  }

  _handleGA(input) {
    // Send 'Page View' record to Google Analytics
    sendGaPageView(input.virtual_path, merge(this.tData.getProperties(), this.extras));
  }

  _getViewProperties() {
    const v = {};
    const win = getWindow();
    const doc = getDocument();

    v.browx = win.innerWidth ? win.innerWidth : doc.body.offsetWidth;
    v.browy = win.innerHeight ? win.innerHeight : doc.body.offsetHeight;
    v.resx = win.screen.width;
    v.resy = win.screen.height;
    v.cdepth = win.screen.colorDepth;

    return v;
  }

  preProcess() {
    // Build tData object
    this.tData = createTData(this.data);

    let pathname = this.data.ALL.virtual_path;

    if (typeof (pathname) === 'undefined') {
      // Send just the pathname to Traffic unless a virtual page was specified
      pathname = getWindow().location.pathname;
    } else {
      page.set('virtual_path', pathname);
    }

    if (process.env.NODE_ENV !== 'production') {
      debug('cmd: LogPageRequest invoked with path', pathname);
    }

    if (lastPathName === pathname) {
      throw 'cmd: LogPageRequest duplicate page request detected, ignoring duplicate path';
    }
    lastPathName = pathname;

    // Add generic event properties
    page.merge(createEventProperties('page.request', this.tData));

    page.set('page', formatPageName(pathname));
    page.set('hw', getWindow().history.length);

    // merge properties from browser view stats
    page.merge(this._getViewProperties());

    // get query string from URL
    const qs = getCleanQueryString();
    if (qs) {
      page.set('querystring', qs);
    }

    page.merge(getCookieValues('tcc_refer'));
    setCookie('tcc_refer', '', -1, '/');

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    page.merge(this.extras);
  }
}

export default AddPageRequestHandler;
