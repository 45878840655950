import { getWindow } from '@exp/exp-utils/helper/browser';
import { getObjFromTDataList } from  '@tcc/shared/src/helpers/tdata';

const cmdLogPageEvent = (eventDesc, eid, usrin, domElement, domEvent) => {
  getWindow()._expDataLayer.push({
    schema: 'add_event',
    version: 'v1',
    data: {
      type: eventDesc,
      eid: eid,
      custom_properties: getObjFromTDataList(usrin),
      dom_element: domElement,
      dom_event: domEvent }});
};

export default cmdLogPageEvent;
