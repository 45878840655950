import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import { getWindow as getBrowserWindow } from '@exp/exp-utils/helper/browser';

import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

class AddVirtualPagePerfHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: (input) => { this._handle(input); }
    });
  }

  _handle(input) {
    // call the 'add_perf' schema
    this.schemaHelper.handleSchema('add_perf', undefined, 'v1', {
      type: 'pageperf',
      properties: objectMerge(input.timing_metrics, {
        nav_type: 'soft',
        // TODO: Remove after legacy traffic feeds have been deprecated
        page: input.virtual_path,
        virtual_path: input.virtual_path }),
      custom_properties: this.data.EVENT_SVC.custom_properties
    },
    this.extras,
    // this schema's sinks will override add_perf's sinks
    this.sinks);

    // Set a performance mark if name is provided and an error did not occur while processing
    // the virtual perf event.
    const w = getBrowserWindow();
    if (input.perf_mark_name && w.performance && w.performance.mark) {
      w.performance.mark(input.perf_mark_name);
    }
  }
}

export default AddVirtualPagePerfHandler;
