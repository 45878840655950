import { getWindow } from '@exp/exp-utils/helper/browser';

const cmdGetTrackingValues = (callback) => {
  getWindow()._expDataLayer.push({
    schema: 'get_tracking_values',
    version: 'v1',
    data: {
      callback: callback }});
};

export default cmdGetTrackingValues;
