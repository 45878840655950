import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { getPerformanceData } from '@tcc/shared/src/performance/performance';

class AddPagePerfHandler extends SchemaHandler {
  process() {
    super.process({
      EVENT_SVC: (input) => { this._handleEventSvc(input); }
    });
  }

  _handleEventSvc(input) {
    getPerformanceData('tcc', 'manual', input.custom_properties);
  }
}

export default AddPagePerfHandler;
