import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { validateEid } from '@tcc/shared/src/experiments/experimentSchemaTransforms';

import { sendEcommerceEvent as sendGaEcommerceEvent } from '../../ga/ga';
import { createTData } from '../../traffic/eventProperties';

class AddPromotionHandler extends SchemaHandler {
  process() {
    super.process({
      EVENT_SVC: (input) => { this._handleEventSvc(input); },
      GA: (input) => { this._handleGA(input); }
    });
  }

  preProcess() {
    if (this.schemaType === 'click') {
      this.eid = 'traffic.tcc.instrumentation.internal_promotion.click';
      this.event_category = 'internal_promotion_click';
      this.gaType = 'select_content';
    } else if (this.schemaType === 'impression') {
      this.eid = 'traffic.tcc.instrumentation.internal_promotion.impression';
      this.event_category = 'internal_promotion_impression';
      this.gaType = 'view_promotion';
    }
    this.tData = createTData(this.data);
  }

  _handleGA(input) {
    const filterMap = objectMerge(this.tData.getProperties(), this.extras);
    const gaData = objectMerge(this.extras, input, {
      eid: this.eid,
      event_category: this.event_category });
    sendGaEcommerceEvent(this.gaType, gaData, filterMap);
  }

  _logTrafficEvent(type, input) {
    try {
      // will throw an exception if the eid is not provided as a parameter or
      // within the provided dom_element
      validateEid(input);

      const addEventVersion = this.schemaVersion === 'v1' ? 'v1' : 'v2';
      // call the 'add_event' schema
      this.schemaHelper.handleSchema(
        'add_event',
        undefined,
        addEventVersion,
        objectMerge({ type: type }, input),
        this.extras,
        // only send traffic as the output group, else another GA hit would be fired
        ['EVENT_SVC']);
    } catch (error) {
      // No need to do anything here. For "add_promotion", eid is not required.
      // We simply won't send the record to GA if the eid is not provided.
    }
  }

  _handleEventSvc(input) {
    if (input.promotions) {
      for (let i = 0; i < input.promotions.length; i++) {
        this._logTrafficEvent(this.schemaType, input.promotions[i]);
      }
    } else {
      this._logTrafficEvent(this.schemaType, input);
    }
  }
}

export default AddPromotionHandler;
