
import { error } from '@exp/exp-utils/helper/logger';
import { merge, map, stringify } from '@exp/exp-utils/helper/object';
import { getClickEventData as getBrowserClickEventData } from '@exp/exp-utils/helper/browser';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { createEventProperties, addEventProperties, getEventSvcProperties, createTData } from '../../traffic/eventProperties';
import { sendEvent } from '@tcc/shared/src/traffic/eventSend';

import { encodeProperties } from '@exp/exp-utils/helper/url';
import { setCookie } from '@exp/exp-utils/helper/cookie';

import { sendPageEvent as sendGAPageEvent } from '../../ga/ga';

import { setReferCookie } from '@tcc/shared/src/helpers/visit';

// All eventtypes are shortened down to 10 characters.
// Therefore, the below events should not exceed 10 characters.
// https://confluence.godaddy.com/display/CKPT/Non+Interactive+Events
const nonInteractiveEvents = [
  'impression', 'experiment', 'load', 'apicall',
  'split-test', 'redux', 'change', 'custom',
  'impress', 'blur', 'view', 'applog', 'show',
  'success', 'perf', 'loaded', 'clicktaleu', 'notify',
  'clicktale', 'consent', 'timing'];

class AddEventHandler extends SchemaHandler {
  process() {
    // Don't process event if event contains invalid characters
    if (!this._validateEIDCharacters(this.pageEvent.get('e_id'))) return;

    // Validate EID formating after ensuring event is not going to be dropped.
    this._validateEIDFormat(this.pageEvent.get('e_id'));

    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); },
      GA: () => { this._handleGA(); }
    });
  }

  _handleEventSvc() {
    sendEvent(
      merge(this.pageEvent.getProperties(), getEventSvcProperties()),
      '/pageEvents.aspx',
      'GET');
  }

  _handleGA() {
    if (this.pageEvent.get('e_id').startsWith('hivemind'))
      return;

    // Send 'Event' record to Google Analytics
    sendGAPageEvent(
      this.pageEvent.get('e_id'),
      this.pageEvent.getProperties(),
      merge(this.tData.getProperties(), this.extras),
      this.nonInteraction);
  }

  _validateEIDFormat(eId) {
    const validEID = /^([a-z0-9_\-]+)\.([a-z0-9_\-]+)\.(([a-z0-9_\/\-]+)\.)?([a-z0-9_\/\-]+)\.([a-z0-9_\-]+)\.([a-z0-9_\-]+)$/g;
    const isValid = eId && eId.match(validEID) && eId.length <= 500;
    if (!isValid) {
      error('Invalid e_ID naming syntax for', eId, 'See', 'https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats');
    }
    return !!isValid;
  }

  _validateEIDCharacters(eId) {
    const invalidCharacters = /[^a-z0-9\_\/\.\-]/g;
    const hasInvalidCharacters = eId && eId.match(invalidCharacters);
    if (hasInvalidCharacters) {
      error(`Invalid characters in e_ID: ${eId} The event has been DROPPED. See https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats`);
    }

    return !hasInvalidCharacters;
  }

  preProcess() {
    this.tData = createTData(this.data);

    // Some pages might still be using the deprecated property "properties" as custom properties
    this.tData.merge(this.data.ALL.properties);

    this.pageEvent = createEventProperties('page.event', this.tData);
    addEventProperties(this.pageEvent, this.data.ALL.type);

    // We will only accept the dom_element and dom_event in the v1 schema
    this.pageEvent.merge(getBrowserClickEventData(this.data.ALL.dom_element, this.data.ALL.dom_event));

    // in the v2 schema, the click event data is supplied as a property
    this.pageEvent.merge({
      href: this.data.ALL.href,
      tcode: this.data.ALL.tcode,
      tms: this.data.ALL.tms,
      ci: this.data.ALL.ci
    });

    // override dom element attributes with those passed in.
    if (this.data.ALL.eid) {
      this.pageEvent.set('e_id', this.data.ALL.eid);
    }

    // Use event_label provided from schema (if provided) before one provided in tdata (if provided)
    const eventLabel = this.data.ALL.event_label || this.tData.getProperties().event_label;
    if (eventLabel) {
      this.pageEvent.set('event_label', eventLabel);
    }

    // Convert EID to lower case characters
    this.pageEvent.set('e_id', this.pageEvent.get('e_id').toLowerCase());

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);

    // Set refer cookie based on whether this was an interactive event
    this._setNonInteraction();
  }

  _setNonInteraction() {
    this.nonInteraction = nonInteractiveEvents.indexOf(this.data.ALL.type.toLowerCase()) !== -1;
    if (!this.nonInteraction) {
      setReferCookie(
        this.pageEvent.get('e_id'),
        this.pageEvent.get('usrin'),
        this.pageEvent.get('tcode'),
        this.pageEvent.get('ci'),
        this.pageEvent.get('tms'),
        // Client-side session identifier.
        this.pageEvent.get('corrid'));
    }
  }

  _setReferCookie(eid, usrin, tcode, ci, tms, corrid) {
    const dataProps = {};
    // delete refer cookie
    setCookie('tcc_refer', '', -1, '/');
    if (eid) {
      dataProps.refer_e_id = eid;
    }
    if (usrin) {
      dataProps.refer_usrin = usrin;
    }
    if (tcode) {
      dataProps.refer_tcode = tcode;
    }
    if (ci) {
      dataProps.refer_ci = ci;
    }
    if (tms) {
      dataProps.refer_tms = tms;
    }
    if (corrid) {
      dataProps.refer_corrid = corrid;
    }
    map(dataProps, () => {
      const encodedProperties = encodeProperties(dataProps);
      setCookie('tcc_refer', stringify(encodedProperties, '&', '='), 5, '/');
      return true;
    });
  }
}

export default AddEventHandler;
