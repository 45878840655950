import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import SchemaHandler  from '@exp/exp-utils/schema/schemaHandler';

import { sendPageEvent } from '../../ga/ga';
import { sendGenericConversion } from '../../tealium/tealium';
import { createTData } from '../../traffic/eventProperties';

class AddGenericConversion extends SchemaHandler {
  process() {
    super.process({
      TEALIUM: (input) => { this._handleTealium(input); },
      GA: (input) => { this._handleGA(input); },
      EVENT_SVC: (input) => { this._handleEventSvc(input); }
    });
  }

  _handleGA(input) {
    sendPageEvent(input.eid, input, objectMerge(this.extras, createTData(this.data).getProperties()), true);
  }

  _handleTealium(input) {
    sendGenericConversion(input);
  }

  _handleEventSvc(input) {
    this.schemaHelper.handleSchema(
      'add_event',
      undefined,
      'v1',
      objectMerge({ type: 'genConv' }, input),
      this.extras,
      // This call should only go to the event service
      // The current handler will handle calls to GA/Tealium
      ['EVENT_SVC']);
  }
}

export default AddGenericConversion;
