import { getWindow } from '@exp/exp-utils/helper/browser';

const cmdLog = (eventDesc, propertyObject) => {
  getWindow()._expDataLayer.push({
    schema: 'add_perf',
    version: 'v1',
    data: {
      type: eventDesc,
      properties: propertyObject }});
};

export default cmdLog;
